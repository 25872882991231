.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.str-chat__channel-list-messenger-react__main {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.str-chat__channel-list-messenger-react__main::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.rnf-notification-feed {
  width: 100% !important;
}

.rnf-notification-feed__container {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.rnf-notification-feed__container::-webkit-scrollbar {
  display: none !important;
}
