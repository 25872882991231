.input-time-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-time-wrapper input[type='time'] {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
}

.input-time-wrapper input[type='time']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

.start-time-wrapper > div {
  border: 0;
}

.start-time-wrapper .select__value-container {
  padding: 0;
}

.start-time-wrapper .select__control {
  padding: 0;
}

.start-time-wrapper .select__single-value {
  font-size: 16px !important;
  margin: 0;
  text-align: center;
}
.start-time-wrapper .select__input {
  min-width: 26px !important;
}

.start-time-wrapper .select__menu {
  width: 40px;
}

.start-time-wrapper .select__option {
  padding: 4px 2px;
  text-align: center;
}

.start-time-wrapper .select__input-container {
  width: 26px;
  margin: 0;
  padding: 0;
}
