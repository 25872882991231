.not_added>* {
    background-color: white;
    border-color: theme('colors.SeabiscuitGray500ThemeColor')
}

.added>* {
    background-color: theme('colors.SeabiscuitGrayThemeColor');
    border-color: white;
}

.expired>* {
    background-color: #EB57571A;
    color: #EB5757;
    border: 1px solid #eb57571a
}

.cols_container>* {
    padding: 15px;
    border-radius: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-width: 1px;
}

.cols_container {
    margin-bottom: 10px;
    font-size: 14px;
    color: theme("colors.SeabiscuitDark200ThemeColor")
}