.show-detail-page__container {
    --background: #F6F7FB;
}


ion-item.editEventTabs::part(native) {
    padding: 0px !important;
    background-color: #F6F7FB;
}

.manage_event_tabs_container>button:disabled {
    color: #122B4680;
    background-color: #122B460D;
}