.rowClass[aria-disabled="true"] button {
    background-color: #1f41731a !important;
    border-color: #1f41731a !important;
    pointer-events: none;
}

.rowClass[aria-disabled="true"] button * {
    color: #1f417380 !important;
}

.rowClass[aria-disabled="true"]>div {
    color: #1f417380 !important;
}

.rowClass[aria-disabled="true"] svg path{
    fill: #1f417380 !important;
}