.editEventPaperWork .tw-content .tw-title {
  min-width: 420px;
}

.costing::before {
  content: '$';
  color: #122b46;
  display: block;
  position: absolute;
  left: 0;
  z-index: 9999;
}

.percentage::before {
  content: '%';
  color: #122b46;
  display: block;
  position: absolute;
  left: 0;
  z-index: 9999;
}

div.entries-select__menu-list {
  color: black;
  font-size: 14px;
}

div.entries-select__multi-value {
  cursor: pointer;
}

div.entries-select__control {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  min-height: auto;
}

div.entries-select__placeholder {
  font-size: 14px;
  cursor: pointer;
}

.entries-select__indicator-separator {
  display: none;
}

div.entries-select__indicator.entries-select__dropdown-indicator,
div.entries-select__value-container {
  padding: 0;
}

.entries-select__option {
  cursor: pointer !important;
  transition: all;
}

.entries-select__option:hover {
  opacity: 0.7;
}

div.entries-select__single-value {
  font-size: 14px;
}
