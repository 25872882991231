.tw-button {
    width: 108px;
    height: 50px;
    border-radius: 10px;
    outline: none;
    -webkit-transition: background 0.4s;
    transition: background 0.4s;
    display: flex;
    align-items: center;
}

.tw-button span {
    position: absolute;
    top: unset !important;
    left: 4px;
    width: 43%;
    height: calc(100% - 8px);
    border-radius: 10px;
    -webkit-transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
    transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
}

.tw-button:active span {
    transform: scaleX(1.2);
    transform-origin: left;
}

.tw-button .btn-text.yes {
    left: 0;
}

.tw-button .btn-text.no {
    right: 0;
}

.tw-button:active.yes span {
    transform: translate(-100%, 0) scaleX(1.2);
    transform-origin: right;
}

.tw-button.yes span {
    left: calc(100% - 8px);
    transform: translate(-100%, 0);
}

.tw-button .btn-text {
    width: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
