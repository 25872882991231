.event-post-horizontal-card-component {
  .swiper-lazy-loaded + .swiper-lazy-preloader-wrapper {
    display: none;
  }
}

.event-organization-horizontal-card-component {
  width: 393px;
  height: 318px;

  background: #FFFFFF;

  box-shadow: 0 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 20px;
}

.event-organization-horizontal-card-component__container {
  width: 393px;
  height: 318px;

  background: #FFFFFF;

  box-shadow: 0 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 20px;
}

.event-organization-horizontal-card-component__image {

  width: 393px;
  height: 159px;

  border-radius: 20px 20px 0 0;

}

.event-organization-horizontal-card-component__upper-right-label {
  position: absolute;
  top: 11px;
  left: 253px;
  width: 131px;
  height: 36px;

  background: #FFDEE8;
  border-radius: 20px;
}

.event-organization-horizontal-card-component__upper-right-label__text {
  position: absolute;
  width: 99px;
  height: 36px;

  left: 28px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #F70763;
}

.event-organization-horizontal-card-component__card__title {

  width: 288px;
  height: 30px;

  font-style: normal;
  font-weight: 600;
  color: #122B46;
  font-size: 14px;
}


.event-organization-horizontal-card-component__card__registration__counter__text {
  width: 264px;
  height: 21px;
  margin-top: -2px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: #F70763;
}

.event-organization-horizontal-card-component__card__registration__closing-text {
  margin-top: -2px;
}

.event-organization-horizontal-card-component__card__registration__sales {
  width: 288px;
  height: 30px;
}
