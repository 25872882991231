@font-face {
  font-family: 'PoppinsRegular';
  src:
    local('PoppinsRegular'),
    url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'PoppinsBold';
  src:
    local('PoppinsBold'),
    url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'PoppinsExtraBold';
  src:
    local('PoppinsExtraBold'),
    url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 900;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.home_root_ion_list {
  box-shadow: 0 2px 10px rgb(163 171 185 / 20%);
  background: white !important;
  margin-top: 16px !important;
  border-radius: 20px;
  width: 100%;
  padding: 0 !important;
  flex: 1 0 auto;
}
