.MainCheckbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.row {
    display: flex;
}

.app_logo {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.row .col {
    flex: 1;
}

.row .col:last-child {
    margin-left: 1em;
}

/* Accordion styles */
.tabs {
    border-radius: 8px;
    overflow: hidden;
}

.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: transparent;
    font-weight: bold;
    cursor: pointer;
    color: #122B46B2;
    /* Icon */
}

.tab-label:hover {
    background: transparent;
}

.tab-label::after {
    content: "\002B";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
    border: 2px solid #122B46B2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    transition: content 2s;
}

.tab-content {
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    transition: all 0.35s;
    height: 0px;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: transparent;
    cursor: pointer;
}

.tab-close:hover {
    background: transparent;
}

input:checked+.tab-label {
    background: transparent;
}

input:checked+.tab-label::after {
    content: "\002D";
    transition: content 2s;
    color: #F70763;
    border-color: #F70763;

}

input:checked+.tab-label .span {
    font-weight: 600;
    color: #F70763;
}

input:checked~.tab-content {
    max-height: 100%;
    padding: 1em;
    border: 1px solid #122B4633;
    border-radius: 8px;
    height: unset;
}

/* .paperwork_document {
    height: calc(100% - 49px);
} */
