input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #f9faf9 inset !important;
}


input:-webkit-autofill::first-line {
    color: black !important;
}