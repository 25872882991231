@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* ----------------------- Start Header Section -------------------- */
.main_div {
  position: relative;
  width: 100%;
  height: 3992px;
  background: #ffffff;
  box-shadow: 0 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 20px;
  padding: 20px;
}

.score {
  /* position: absolute; */
  width: 1555px;
  height: 34px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: #f70763;
}

.dressage_div {
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.dressage {
  /* position: absolute; */
  width: 455px;
  height: 33px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #f70763;
}

.secound_dev_small {
  margin-right: 20px;
}

/* ---------------------- End Header Section ------------------- */

.row {
  margin: 5px 60px 5px 112px;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.525);
}

.sub_div_span {
  margin-right: 10px;
}

.img_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_box img {
  height: 30px;
  width: 20px;
  margin-right: 8px;
  opacity: 40%;
}

.col_2_active .img_box img {
  height: 28px;
  width: 20px;
  margin-right: 22px;
  opacity: 100%;
}

.col_1 {
  /* Rectangle 5142 */
  margin: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 604px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #bcc6d5;
  border-radius: 8px;
  padding: 15px;
  /* position: absolute; */
}

.col_2 {
  /* Rectangle 5142 */
  margin: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-sizing: border-box; */
  padding: 15px;
  width: 820px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #bcc6d5;
  border-radius: 8px;
}

/* .col_2 .col_2_select_div{
    padding: 0px!important;
    margin: 0px!important;
} */

.col_2 select {
  width: 433px;
  border: none;
  outline: none !important;
  margin: 0;
  padding-left: 10px;
}

/* #00b6aa */

input[type='checkbox'] {
  border-radius: 50%;
}

/* input[type=checkbox]:checked {
    border-radius: 50%;
    outline: none !important;
    border: 1px solid #00b6aa !important;
    background-color: #00b6aa;
    color: #00b6aa;
} */

.main_div input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  margin-top: 0;
  border-radius: 50%;
  /* Centers thumb on the track */
  background-color: #f70763;
  height: 1rem;
  width: 1rem;
}

/***** Firefox *****/
.main_div input[type='range']::-moz-range-thumb {
  border: none;
  /*Removes extra border that FF applies*/
  border-radius: 50%;
  /*Removes default border-radius that FF applies*/
  background-color: #f70763;
  height: 1rem;
  width: 1rem;
}

.main_div input[type='checkbox']:active {
  border-radius: 50%;
  outline: none !important;
  border: none;
}

.rc-tooltip-inner {
  background-color: #f70763 !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: #f70763 !important;
}

.col_1_active {
  /* Rectangle 5142 */
  margin: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 604px;
  height: 56px;
  background: #f6f7fb;
  border: 1px solid #f6f7fb;
  border-radius: 8px;
}

.col_2_active {
  /* Rectangle 5142 */
  margin: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 820px;
  height: 56px;
  background: #f6f7fb;
  border: 1px solid #f6f7fb;
  border-radius: 8px;
}

.col_1_active span,
.col_1_active small,
.col_1_active .icon,
.col_2_active span,
.col_2_active small,
.col_2_active .icon,
.col_1 span,
.col_1 small,
.col_1 .icon,
.col_2 span,
.col_2 small,
.col_2 .icon,
select {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #122b46;
}

/* ----------------------- custom_label --------------- */

.custom_label {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px;
  border-top: 1px solid #e6eaf2;
  border-bottom: 1px solid #e6eaf2;
}

.custom_label_2 {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 8px 8px 8px;
  border-bottom: 1px solid #e6eaf2;
}

.custom_label span,
.custom_label_2 span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #122b46;
}

.custom_label span:first-child,
.custom_label_2 span:first-child {
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .row {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.525);
  }

  .col_2,
  .col_1,
  .col_1_active,
  .col_2_active {
    width: 290px;
    height: auto;
    box-sizing: border-box;
  }

  .col_2 select {
    width: 240px;
    border: none;
    outline: none !important;
  }
}

@media only screen and (max-width: 736px) {
  .row {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.525);
  }

  .col_2,
  .col_1,
  .col_1_active,
  .col_2_active {
    width: 328px;
    height: auto;
    box-sizing: border-box;
  }

  .col_2 select {
    width: 276px;
    border: none;
    outline: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .row {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.525);
  }

  .col_2,
  .col_1,
  .col_1_active,
  .col_2_active {
    width: 200px;
    height: auto;
    box-sizing: border-box;
  }

  .col_2 select {
    width: 276px;
    border: none;
    outline: none !important;
  }
}
