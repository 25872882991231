.startEndDateFilter {
    z-index: 99;
    padding-bottom: 0px !important;
    background: transparent;
    border: none;
}

.startEndDateFilter:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    background: #b5b5b5;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}