.event-post-horizontal-card-component {
  background: #ffffff;
  border-radius: 20px;
}

.event-post-horizontal-card-component__image {
  border-radius: 20px;
  width: 100% !important;
}

.event-post-horizontal-card-component__date-label {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
}

.event-post-horizontal-card-component__bookmark-label {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 25px;
  height: 24px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.event-post-horizontal-card-component__upper-date-day__text {
  font-weight: bolder;
  font-style: normal;
  color: #fff;
  font-size: 16px;
  line-height: 1;
}

.event-post-horizontal-card-component__upper-date-month__text {
  font-weight: bold;
  font-style: normal;
  font-size: 11px;
  line-height: 1;
  color: #fff;
}

.event-post-horizontal-card-component__card__title {
  font-style: normal;
  font-weight: bold;
  white-space: wrap;
  color: hsl(211, 59%, 17%) !important;
  font-size: 14px;
  line-height: 1.2;
}

@media (min-width: 1532px) {
  .event-post-horizontal-card-component__card__title {
    font-size: 20px;
  }
}

.event-post-horizontal-card-component__card__registration__closing-text {
  margin-top: -2px;
}

.event-post-horizontal-card-component__card__registration__sales {
  height: 30px;
}

.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
  font-size: 14px;
  line-height: 14px;
}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  background: #fff;
  border-radius: 50px;
  height: 25px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper:hover ~ .swiper-button-prev,
.swiper:hover ~ .swiper-button-next,
.swiper .swiper-button-next:hover,
.swiper .swiper-button-prev:hover {
  opacity: 1;
}

.swiper .swiper-button-prev.swiper-button-disabled,
.swiper .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

.swiper .swiper-slide img {
  height: 300px !important;
}
