.profile .w-87{
    width: 87px;
}
.profile .w-120{
    width: 120px;
}
.profile .h-50{
    height: 50px;
}
.profile .h-90{
    height: 90px;
}
.profile .opacity-84{
    opacity: 0.84;
}
.profile .mrl-3{
    margin-left: 3px;
    margin-right: 3px;
}
.profile .pb-102{
    padding-bottom: 102px;
}
.profile .event-btn button{
    border-width: 1.5px;
    border-radius: 10px;
    width: 154px;
}
.profile .event-btn button:hover .icon{
    stroke: #F70763;
    transition: all 0.2;
}
.profile .event-tab .before::before{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #F70763;
    border-radius: 12px;
    z-index: 9;
}
.profile .bottom-nav-box{
    padding: 14px 16px 26px;
    box-shadow: 0px -3px 8px rgb(157 178 214 / 13%);
}
.profile .bn-btn-shadow{
    box-shadow: 0px 8px 20px rgba(74, 67, 236, 0.25);
}


