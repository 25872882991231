.EventDetailFormComponent__root {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.EventDetailFormComponent__input {
    border: 0;
}

.courseMapBtn button.no {
    height: 50px;
    background: #1F41731A;
}

.courseMapBtn button{
    width: 120px;
    height: 50px;
}

.toggleButton>div>button>span {
    top: 6px
}

.mediaUploadContainer>div {
    flex-shrink: unset;
    margin-right: 30px;
}

.availableSlots>input {
    text-align: center;
}

.availableSlots>input:focus::placeholder {
    color: #F6F7FB;
}

.toggleButton>div>button {
    height: 50px;
    width: 100%;
}

.selectTime select {
    background-image: none !important;
}

.selectTime .EventTimeSelect {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
    padding-right: 0rem;
    width: auto;
    flex: none !important;
    /* border-radius: 0 !important; */
}

.selectTime .EventTimeSelect select {
    flex: none !important;
    width: auto;
    padding-right: 1rem;
    padding-left: 1rem;
}