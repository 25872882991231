@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

.swiper-pagination {
  width: fit-content !important;
  left: 50% !important;
  transform: translateX(-50%);
}

body {
  font-family: 'Poppins', sans-serif;
}

.css-gc6907-Input,
.css-m8xnif-Input,
svg.css-tj5bde-Svg {
  color: #ccc !important;
}

.offset-bottom {
  --offset-bottom: 20px !important;
  height: 100vh !important;
}

.direct-elems-bg-red > * {
  background-color: #ff000026;
}

.dot_after::after {
  content: '';
  background: #122b46b2;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 4px;
}

.w-\[200px\] {
  width: 200px !important;
}

.tabScroll::-webkit-scrollbar {
  height: 6px;
}

.myEventPage div[role='table'] div[role='rowgroup']:first-child {
  display: none;
}

.rounded_checkboxes {
  border-radius: 2px !important;
  outline: none !important;
}

.indicator_container {
  padding: 0 !important;
  border: 1px solid theme('colors.SeabiscuitGrayLightThemeColor');
  border-radius: 3px;
  margin-right: 10px;
  color: theme('colors.SeabiscuitGrayLightThemeColor') !important;
}

.rounded_checkboxes:checked {
  border: 1px solid #f70763;
  background-color: #f70763 !important;
  color: #f70763;
}

.main-menu-collapsed .sideNavigationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.main-menu-collapsed .sideNavigationItem > a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 0 !important;
  transition: all 0.5s ease;
}
.main-menu-collapsed .collapsed ion-item::part(native) {
  padding-left: 0;
}

.main-menu .sideNavigationItem .sideNavigationLabel {
  opacity: 1;
  transition: opacity 0.5ms ease;
}
.main-menu .sideNavigationItem:not(.selected) .sideNavigationLabel {
  color: white;
}

.main-menu-collapsed .sideNavigationItem .sideNavigationLabel {
  opacity: 0;
  transition: opacity 0.5ms ease;
}

.main-menu-collapsed .sideNavigationItem .sideNavigationIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.tooltip p {
  opacity: 0;
}

.tooltip:hover p {
  opacity: 1;
}

.website_container .globe_4 {
  display: none;
}

.website_container:hover .globe_1 {
  display: none !important;
}

.website_container:hover .globe_4 {
  display: block !important;
}

.roleChecklist {
  display: inline-flex;
  gap: 0.4rem;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
}

.removeDownArrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.roleChecklist .checkbox-input {
  opacity: 0;
  display: none;
  margin: 0;
}

/* Change icon, border and text color when radio is checked */
.checkbox-input:checked + .checkbox-tile,
.checkbox-input:checked + .checkbox-tile span {
  border-color: #f70763;
  color: #f70763;
  padding-left: 12px !important;
  transition-duration: 0.5s;
  line-height: 18px;
}

.checkbox-tile span {
  transition-duration: 0.5s;
}

.checkbox-tile::before {
  margin-top: 4px;
}

/* Checkbox display */
.checkbox-input:checked + .checkbox-tile::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  transform: scale(1);
  opacity: 1;
  padding-left: 0 !important;
  background-color: #fff;
}

.line-clamp-4 {
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

.line-clamp-3 {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

.line-clamp-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

.line-clamp-1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

[data-increment-by]::after {
  content: ' (' attr(data-increment-by) '%)';
}

.backShadow {
  background-color: #0000008f;
}

.roleChecklist .checkbox {
  margin-bottom: 10px;
}

/* Checkbox icon and text */
.roleChecklist .checkbox-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid #ccc;
  transition: 0.1s ease;
  cursor: pointer;
  color: #fff;
  position: relative;
}

.checkbox-tile:hover {
  border-color: #ccc;
}

/* Checkmark (icon inside checker) */
.checkbox-tile::before {
  content: '';
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: #f70763 !important;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  top: 0.25rem;
  left: 0.25rem;
  transition: 0.25s ease;
  border: 2px solid var(--primary-color);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

button {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.arrowBtn {
  width: 10px !important;
}

[type='text']:focus {
  --tw-ring-color: transparent;
  /* border: 1px solid #F70763; */
}

.endoresement_cancel_icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .hourseCategory p:after {
  content: "";
  height: 4px;
  width: 4px;
  background: #122b46b3;
  display: inline-block;
  border-radius: 50px;
  margin: 0 4px;
} */

.hourseCategory p:last-child:after {
  opacity: 0;
}

.zipCodeInput:focus {
  border: none;
  border-bottom: 1px solid #122b4680;
}

.avatarImg {
  height: 40px;
  width: 40px;
}

.horse_tab .avatarImg,
.competitor_team_tab .avatarImg {
  height: 36px;
  width: 36px;
}

.profile_tab .selectWithSearch {
  display: inline-flex;
  align-items: center;
}

.avatarImg2 {
  height: 30px;
  width: 30px;
}

.usefLogo {
  max-height: 120px;
  max-width: 120px;
}

input::placeholder {
  font-size: 14px;
  color: #122b4680;
}

input {
  background-color: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6e9ecc8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcc6d5;
  border-radius: 24px;
}

.react-select-3-listbox li:hover {
  background-color: #f70763;
  color: #fff;
}

.selectWithSearch input:focus {
  outline: 0 !important;
  border: none !important;
  box-shadow: none;
}

.react-datepicker__input-container::after {
  opacity: 0;
}

.react-datepicker__input-container::before {
  width: 0 !important;
  height: 0 !important;
  z-index: 0;
}

select.react-datepicker__month-select,
select.react-datepicker__year-select {
  padding: 2px 30px 2px 2px !important;
  font-size: 12px !important;
}

select.react-datepicker__month-select option,
select.react-datepicker__year-select option {
  font-size: 14px;
}

.selectWithSearch #react-select-3-placeholder {
  padding-left: 0;
}

.react-datepicker__header__dropdown--scroll {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 2px;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  margin-left: -23px;
}

.datepicker-popper-member {
  z-index: 1050;
}

@media screen and (min-width: 767px) {
  .modalRegistrationUpdates .react-datepicker-popper {
    transform: translateX(-50%) !important;
    left: 30% !important;
    top: 100% !important;
  }
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 2.3rem;
  border-top-left-radius: 2.3rem;
}
.react-datepicker__navigation-icon {
  top: 6px;
}

/* input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff !important;
} */

.react-datepicker-wrapper {
  max-width: 100%;
}

main.inner-scroll.scroll-y::-webkit-scrollbar {
  display: none;
  opacity: 0;
}

.searchableComponent input[type='text']:focus {
  outline-offset: 0 !important;
  outline: 0 !important;
}

.nextBtnShadow:hover {
  box-shadow: 0 10px 35px 0 rgb(111 126 201 / 20%) !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}

.tooltip_title {
  font-size: 14px !important;
  font-weight: 500 !important;
  max-width: 265px;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop.css-1k51tf5-MuiTooltip-tooltip {
  max-width: 380px !important;
  height: fit-content;
  min-height: 60px !important;
  display: flex !important;
  align-items: center !important;
}

.teamMemberAuth {
  appearance: none;
  background-image: url('../public/assets/og_icons/PublicSafety.svg');
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
}

.teamMemberAuth option {
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

/* React DatePicker Css */

.react-datepicker {
  border: 1px solid #d3daee;
  border-radius: 12px;
}

.react-datepicker__day {
  color: theme('colors.SeabiscuitDark200ThemeColor') !important;
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;
}

.react-datepicker__day--selected {
  border-radius: 50% !important;
  background-color: theme('colors.SeabiscuitMainThemeColor') !important;
  /* background-color: theme("colors.SeabiscuitDark200ThemeColor") !important; */
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__header {
  background: white !important;
  border: none;
  padding-bottom: 0 !important;
}
.swiper-backface-hidden .swiper-slide {
  min-height: 100%;
}
.react-datepicker__day-name {
  font-size: 12px !important;
  margin-left: 4px;
  margin-right: 4px;
  color: theme('colors.SeabiscuitLightThemeColorFilter') !important;
}

.react-datepicker__day--disabled {
  color: theme('colors.SeabiscuitGray600ThemeColor') !important;
}

.react-datepicker__day--disabled.react-datepicker__day--selected {
  color: white !important;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

.startEndDateFilter {
  grid-template-rows: 46px;
}

/* React DatePicker Css */

.forceHide {
  opacity: 0;
}

ion-item.ulfat::part(native) {
  background: transparent;
}

.remove-child-pl::part(native) {
  padding-left: 0;
}

.makefieldautofit {
  width: max-content !important;
  max-width: 100%;
}

.makegloballabelcolor {
  color: rgba(31, 65, 115, 1) !important;
}

.cover_info {
  overflow: auto;
}

.registration_tabs_column_title {
  color: #122b46;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

input:placeholder-shown {
  text-overflow: ellipsis !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.profile_left_container {
  min-height: 1000px;
  width: fit-content;
  overflow-y: auto;
}

.overflow-auto::-webkit-scrollbar {
  display: none;
}

.customnumber:focus {
  border: none !important;
  outline: none !important;
  --tw-ring-color: #d9d9d9;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
}

.text-field__control .text-field__dropdown-indicator {
  padding: 0;
}

.event_comp {
  @apply text-SeabiscuitDark200ThemeColor flex flex-wrap gap-2 items-start md:items-center py-[18px];
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#redBar {
  /* stroke: theme("colors.SeabiscuitMainThemeColor"); */
  transform-origin: 50px 50px;
  animation: spin 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink_img {
  animation: blinker 3s linear infinite;
}

.accOpen .customValue {
  color: rgb(247 7 99 / 1);
}

.unSelected .item-native {
  background: transparent !important;
}

@media screen and (max-width: 678px) {
  .main-menu {
    max-width: 100% !important;
    width: 100%;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
