#my-progress {
  width: 100%;
  height: .5rem;
  background: #F6F7FB;
  margin: auto;
  border-radius: 1rem
}

#my-bar {
  width: 4%;
  /* background: #F70763; */
  height: 0.5rem;
  border-radius: 1rem;
}

.in-progress {
  background-color: #F70763;
}

.completed {
  background-color: #00B6AA;
}

.text {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #122B46;
}

.text-2 {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(31, 65, 115, 0.5);
}
