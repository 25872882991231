button.gm-ui-hover-effect {
  visibility: hidden;
}


.gm-style-iw-d {
  padding: 0px !important;
  overflow: unset !important;
}

.gm-style-iw.gm-style-iw-c{
  padding: 0px !important;
}

.gm-style .gm-style-iw-tc::after{
  background: theme("colors.SeabiscuitMainThemeColor")
}

.marker-tooltip{
  background: theme("colors.SeabiscuitMainThemeColor");
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  font-weight: 400;
}