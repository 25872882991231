html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
}
#root {
  display: flex;
}

.str-chat__channel-list {
  width: 100%;
  border: 0;
}
.str-chat__channel {
  width: 100%;
}
.str-chat__thread {
  width: 45%;
}

.str-chat__channel-header {
  border-bottom: 1px solid #f6f7fb;
}

.image-gallery {
  height: 80%;
  width: 80%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 25px;
  width: 25px;
}
