.main-menu {
  width: 209px;
  transition: all 0.5s ease;
}

.main-menu-collapsed {
  width: 80px;
  transition: all 0.5s ease;
}

.togglerIcon {
  position: absolute;
  cursor: pointer;
  transition: all 0.5s ease;
}

.collapsed .sideNavigationLabel {
  display: none !important;
}

.collapsed ion-item::part(native) {
  padding-left: 20px;
}

.collapsed .input-wrapper {
  justify-content: center !important;
}

.collapsed ion-menu.md ion-item {
  --padding-start: 0 !important;
}

.collapsed .sideNavigationIcon {
  margin-right: 0px !important;
}

.collapsed .togglerContainer {
  justify-content: center;
}

.collapsed .togglerIcon {
  position: unset;
}

.unCollapsed .togglerIcon {
  right: 12px;
}

ion-menu ion-content {
  --background: var(--ion-color-primary, var(--ion-color-primary, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0px !important;
  background-color: transparent !important;
  margin: 0px !important;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-note {
  padding-left: 10px;
}

.collapsed ion-menu.md ion-list#inbox-list ion-list-header {
  padding-left: 0px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

.unCollapsed ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-menu.md ion-item {
  border-radius: 4px;
  color: var(--ion-color-secondary);
  --background: rgba(var(--ion-color-primary-rgb), 1);
  border-radius: 8px;
}

ion-menu.md ion-item .item-native {
  color: white !important;
}

ion-menu.md ion-item.selected {
  color: #f70763;
  --background: rgba(var(--ion-color-secondary-rgb), 1);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: var(--ion-color-secondary);
  margin-right: 8px;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-item:hover ion-label {
  color: #fff !important;
}

ion-item.selected:hover ion-label {
  color: #f70763 !important;
}
