main {
  max-width: 100%;
  margin: 0 auto;
  padding: 30px 30px 60px 30px;
  text-align: left;
}

.faqs-item {
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  display: block;
}

.faqs-title {
  display: flex;
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 100%;
  outline: none;
  transition: 0.4s;
}

.faqs-title:after {
  content: "\002B";
  color: #fff;
  font-weight: normal;
  float: right;
  margin-left: auto;
  font-size: 24px;
  line-height: 1;
  padding-left: 20px;
}

.faqs-content {
  padding: 0;
  max-height: 0;
  overflow: visible;
  display: none;
  transition: max-height 0.2s ease-out;
}

.faqs-content.activeTab {
  display: block;
  max-height: 100% !important;
  transition: max-height 0.2s ease-in;
}

.css-1d8eghy-ValueContainer {
  margin-left: 2.5rem;
}

.css-1xfpmts-ValueContainer {
  padding-left: 34px !important;
}

.avatarImg23 {
  width: 24px;
  height: 24px;
}
