#main-split-pane {
  --side-max-width: 209px;
  --side-min-width: 209px;
  transition: all 0.5s ease;
}

#main-split-pane1 {
  --side-max-width: 80px;
  --side-min-width: 80px;
  transition: all 0.5s ease;
}

.removeScroll::-webkit-scrollbar {
  display: none;
}

.scroll-y{
  padding-bottom: 0px !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.removeScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  scrollbar-width: thin;
}
