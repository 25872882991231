div.bulk-add__menu-list {
  color: black;
  font-size: 14px;
}

div.bulk-add__multi-value {
  cursor: pointer;
}

div.bulk-add__control {
  background-color: transparent;
  border: none;
  width: 120px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  min-height: auto;
}

div.bulk-add__menu {
  min-width: 250px;
  text-align: left;
}
div.bulk-add__placeholder {
  font-size: 14px;
  cursor: pointer;
}

.bulk-add__indicator-separator {
  display: none;
}

div.bulk-add__indicator.bulk-add__dropdown-indicator,
div.bulk-add__value-container {
  padding: 0;
}

.bulk-add__option {
  cursor: pointer !important;
  transition: all;
}

.bulk-add__option:hover {
  opacity: 0.7;
}

div.bulk-add__single-value {
  font-size: 14px;
}
