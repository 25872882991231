.profile .w-87 {
    width: 87px;
}

.profile .w-120 {
    width: 120px;
}

.profile .h-50 {
    height: 50px;
}

.profile .h-90 {
    height: 90px;
}

.profile .opacity-84 {
    opacity: 0.84;
}

.profile .mrl-3 {
    margin-left: 3px;
    margin-right: 3px;
}

.profile .pb-102 {
    padding-bottom: 102px;
}

.profile .event-btn button {
    border-width: 1.5px;
    border-radius: 10px;
    width: 154px;
}

.profile .event-btn button:hover .icon {
    stroke: #F70763;
    transition: all 0.2;
}

.profile .event-tab .before::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #F70763;
    border-radius: 12px;
    z-index: 9;
}

.profile .bottom-nav-box {
    padding: 14px 16px 26px;
    box-shadow: 0px -3px 8px rgb(157 178 214 / 13%);
}

.profile .bn-btn-shadow {
    box-shadow: 0px 8px 20px rgba(74, 67, 236, 0.25);
}

.saveBtn {
    border: 1px solid #F70763 !important;
    transition-duration: 0.4s;
}

.saveBtn:hover {
    transition-duration: 0.4s;
    border: 1px solid #F70763 !important;
    color: #fff;
    background-color: #F70763 !important;
}

/* .userList:not(:last-child) {
    margin-bottom: 15px;
} */

.tooltip {
    font-size: 10px;
    height: 15px !important;
    width: 15px !important;
    border: 1px solid #122b4680;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.NotificationInput {
    border: 1px solid #D3DAEE;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}
