.judge .item p {
  font-size: 12px;
}

.judge .item p span {
  font-weight: 700;
}

.judge .item h5 {
  font-weight: 700;
  font-size: 16px;
}
