ion-button {
    --border-radius: 10px;
    --border-width: 1px;
    --padding-top: 20px;
    --padding-bottom: 20px;
    /* --background: theme("colors.SeabiscuitMainThemeColor"); */
    /* --color: theme("colors.white"); */
    height: unset;
}

ion-button.loading {
    --padding-top: 15px;
    --padding-bottom: 15px;
}

ion-button.ion-color-muted {
    --background: #1F41731A;
    --color: #1F417380;
}

ion-button.ion-color-transparent {
    --background: transparent;
    --color: theme("colors.SeabiscuitMainThemeColor");
}