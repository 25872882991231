.progress_style, .progress_style2 {
    display: none;
    }
.progress_div:hover .progress_style, .progress_div2:hover .progress_style2 {
    display: flex;
    justify-content: center;
    z-index: 1;

}

