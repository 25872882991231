.tw-button-ticketing {
    width: 100px;
    height: 50px;
    border-radius: 10px;
    outline: none;
    -webkit-transition: background 0.4s;
    transition: background 0.4s;
}

.tw-button-ticketing span {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 45%;
    height: 75%;
    border-radius: 10px;
    -webkit-transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
    transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
}

.tw-button-ticketing:active span {
    width: 56px;
}

.tw-button-ticketing .btn-text.yes {
    left: 0;
}

.tw-button-ticketing .btn-text.no {
    right: 6px;
}

.tw-button-ticketing:active.yes span {
    margin-left: -54px;
}

.tw-button-ticketing.yes span {
    left: 100%;
    margin-left: -50px;
}

.profile-root-page-account-tab .tw-button-ticketing.yes span {
    left: 100%;
    margin-left: -72px !important;
}

.tw-button-ticketing .btn-text {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
